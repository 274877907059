import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { UserContext } from "../context/UserContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutClassPackage from "../components/checkout/CheckoutCourse";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import Footer from "../components/global/Footer";

const Checkout = ({ course_id }) => {
  const { user } = useContext(UserContext);

  //Checkout
  const {
    course,
    descuento,
    setPayPal,
    createOrder,
    setDescuento,
    discountCode,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (descuento !== null && course !== null) {
      setPayPal(course_id, discountCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descuento]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && course !== null && paypalButton !== null) {
      setPayPal(course_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, course]);

  const getInstallmentsOptions = () => {
    if (parseInt(course_id) === 7) {
      return [
        {
          label: "(11% Off) Pago de Contado $22,500 MXN / $1,350 USD aprox.",
          description:
            "Disponible para Tarjetas de Crédito o Débito Internacionales.",
          installments: 1,
        },
        {
          label: "3 Meses Sin Intereses ($25,000 MXN) / $1,500 USD aprox",
          installments: 3,
          description: "Sólo disponible para Tarjetas de Crédito Mexicanas.",
        },
      ];
    }
  };

  const handleSubmit = (payment_source_id) => {
    let discount =
      descuento && descuento !== null ? descuento.code : discountCode;
    createOrder(course_id, payment_source_id, discount);
  };

  const renderBillingDisclaimer = () => {
    if (course && course !== null) {
      if (course.price_id !== null) {
        return (
          <div className="card p-4 border br-10 no-scale mb-4">
            <h4>Recuerda</h4>
            <p>
              * Tu suscripción se renovará automáticamente cada mes hasta que la
              canceles. Puedes cancelar en cualquier momento, al cancelar,
              pierdes tu licencia para impartir clases del método Latina Tribe.
              Los cobros por las suscripciones no son reembolsables. Al realizar
              tu compra nos autorizas a cobrar a través de tu tarjeta de crédito
              o débito, aceptas nuestros Términos de servicio, Políticas de
              cookies y de privacidad.
            </p>
            <p></p>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid px-0">
      <CheckoutHeader />
      <div className="container">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-6 col-xl-4 my-2">
            <CheckoutClassPackage course_id={course_id} />
            <CheckoutDiscount course_id={course_id} />
          </div>
          <div className="col-12 col-md-6 col-xl-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              course_id={course_id}
              paymentMethod={payment_source_id}
              setPaymentMethod={setPaymentSource}
              handleSubmitCheckout={handleSubmit}
              installmentsOptions={getInstallmentsOptions()}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
